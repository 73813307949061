.about-container {
    text-align: center;
    max-width: 1200px;
    margin: 50px auto;
    padding: 3em 1.8em;
}

.about-container h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.about-text {
    display: block;
    margin: 0 auto;
    width: 300px;
}

.about-text > p {
    text-align: left;
    width: 300px;
    font-size: 1rem;
    line-height: 1.6;
    padding-top: 2em;
}

@media screen and (min-width: 800px) {
    .about-text > p {
        width: 600px;
    }
    .about-text {
        width: 600px;
    }
}
