@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&family=Source+Code+Pro:wght@300&display=swap');
/*
font-family: 'Poppins', sans-serif;
font-family: 'Source Code Pro', monospace; 
*/
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;

}
:root {
    --color-background: #223543;
    --color-container: #161a26;
    --color-green: #fdc765;
    --color-light: #bad0de;
    --color-white: #f8fdff;
    --color-blue: #1969ff;

}
.container-app-width{
    width: 1200px;
    margin: 0 auto;
}
.github {
    text-align: center;
    padding-bottom: 1em;
}
.github > a {
    font-size: 40px;
}

body {
    font-family: 'Poppins', sans-serif;
}
span {
    color: var(--color-green);
}
.portfolio {
    color: #001d36;
    text-align: center;
    margin: 3em 0 1em 0;
}
h4 {
    text-align: center;
    padding-bottom: 1em;
}
h3{
    display: block;
    margin: 0 auto 0.3em auto;
    text-align: center;
    margin-bottom: 0.3em;
}
p {
    font-size: 15px;
}
.icon {
    width: 40px;
    height: 40px;
}
.line{
    height: 2px;
    width: 180px;
    margin: 0 auto;
    box-shadow: 0px 5px 10px 0px #222222;
}
.line2{
    height: 2px;
    width: 200px;
    margin: 0 auto 1em;
    box-shadow: 0px 5px 10px 0px #222222;
}

/*============= FOR SMAL BLOCKS ============*/
.colorbox {
    width: 100%;
    height: 10px;
    box-shadow: 0 0px 80px black;
    background: linear-gradient(-45deg, #1c95ff, #001d36, #0071d4, #001d36);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}
  
  @keyframes gradient {
      0% {
          background-position: 0% 50%;
      }
      50% {
          background-position: 100% 50%;
      }
      100% {
          background-position: 0% 50%;
      }
  }
  /*==============================*/
  @media screen and (min-width: 768px) {
    .container-app-width{
        width: 900px;
        margin: 0 auto;
    }
}
@media screen and (min-width: 1200px) {
    .container-app-width{
        width: 1200px;
        margin: 0 auto;
    }
}