
@import url("https://fonts.googleapis.com/css?family=Open+Sans|Roboto:400,700&display=swap");


.work-container{
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    /*background-color: rgba(105, 105, 105, 0.1);
    box-shadow:inset 0px 0px 20px 0px var(--color-container);*/
}
.card {
    width: 310px;
    margin: 2em auto;
    background-color: rgb(17, 1, 56);
    border-radius: 5%;
    box-shadow: 4px 2px 10px black;
}
.photo {
    object-fit: cover;
    margin: 0 auto;
    width: 310px;
    height: 240px;
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;
    border-bottom: 3px solid var(--color-container);
}
.desc {
    text-align: center;
    padding: 1em 0;
}
.desc > p, h1 {
    color: white;
}
.codelink {
    position: relative;
    right: 7.5em;
}
.desc > a {
    padding: 1em;
}
.download-button {
    background-color: #4CAF50; 
    color: white;
    padding: 6px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 13px;
    margin-top: 10px;
  }
  .download-button:hover {
    background-color: #45a049;
  }
@media screen and (min-width: 768px) {
    .work-container{
        grid-template-columns: 1fr 1fr;
        max-width: 900px;
    }
}
@media screen and (min-width: 1200px) {
    .work-container{
        grid-template-columns: 1fr 1fr 1fr;
        max-width: 1400px;
    }
}