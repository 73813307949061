.game-g {
    max-width: 880px;
    margin: 0 auto;
    padding-top: 4em;
}
.h1-g {
    padding: 0 4em;
    text-align: center;
}
.button-g {
    margin-top: 2em;
    font-weight: bold;
    font-size: 1em;
    background: none;
    cursor: pointer;
    transition: opacity 0.25s ease-out;
}
.button-g:hover {
    opacity: 0.8;
}

.card-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}
.card-c {
    position: relative;
    width: 100px;
    height: 100px;
    cursor: pointer;
}
.card-c .cartoon {
    width: 100%;
    height: 100%;
    border: 2px solid #fff;
    border-radius: 6px;
}
.card-c .front, .card-c .back {
    transition: transform 0.2s ease-in;
    position: absolute;
    width: 100%;
    height: 100%;
}
.card-c .front {
    transform: rotateY(90deg);
}
.flipped .front {
    transform: rotateY(0deg);
    transition-delay: 0.2s;
}
.flipped .back {
    transform: rotateY(90deg);
    transition-delay: 0s;
}

.result {
    text-align: center;
    padding: 1em 0;
    font-weight: bold;
}

.popup {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.popup-inner {
    background: white;
    padding: 2em;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: center;
}
.popup-inner > p {
    color: #333;
}
.popup-inner button {
    margin-top: 1em;
    padding: 10px 20px;
    background: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}
.popup-inner button:hover {
    background: #555;
}

@media screen and (min-width: 880px) {
    .card-c {
        width: 200px;
        height: 200px;
    }
    .card-grid {
        width: 880px;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
    }
}
@media screen and (min-width: 1080px) {
    .card-c {
        width: 100px;
        height: 100px;
    }
    .card-grid {
        padding-left: 1em;
        width: 880px;
        grid-template-columns: repeat(7, 1fr);
        gap: 10px;
    }
}
